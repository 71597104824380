import React from "react";
import "./App.css";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import NavBar from "./pages/Nav";
import Footer from "./pages/Footer";
import { AuthProvider } from "./context/AuthProvider";
import RequireAuth from "./components/RequireAuth";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Loading from "./pages/Loading";
function App() {
  return (
    <AuthProvider>
      <div className="wholeApp">
        <div className="topApp">
        <Router>
          <NavBar />
            <div className="innerApp">
              <Routes>
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/loading" element={<Loading />} />
                {/* Prtected Routes */}
                <Route element={<RequireAuth />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                </Route>
              </Routes>
            </div>
          </Router>
        </div>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
