import { createContext, useState } from 'react';

// create authentication contect to be shared across all sub components...
const authContext = createContext({});

// authProvider will accept children and pass auth, setAuth, persist, and setPersist to them...
export const AuthProvider = ({children}) => {
    let [auth, setAuth] = useState({});
    const [user, setUser] = useState({ user: "", avatarURL: ""});
    const [nakamaClient, setNakamaClient] = useState({});
    const [nakamaAccount, setnakamaAccount] = useState({});
    const [nakamaSession, setNakamaSession] = useState({});
    
    let [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || true);
    return (
        <authContext.Provider value={{auth, setAuth, persist, setPersist, user, setUser, nakamaClient, setNakamaClient, nakamaAccount, setnakamaAccount, nakamaSession, setNakamaSession}}>
            {children}
        </authContext.Provider>
    );
}

export default authContext;