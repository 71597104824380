import React, { useState } from 'react';
import LoginForm from '../components/LoginForm';
import axios from 'axios';
import Notification from '../components/Notification';
import {useNavigate, useLocation} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import logo from "../assets/images/primary-Logo.png";

function Login() {
    const [error, setError] = useState("");
    const [code, setCode] = useState(0);
    const nav = useNavigate();
    const loc = useLocation();
    const from = loc.state?.from?.pathname || "/";
    const {setAuth, setNakamaClient, setnakamaAccount, setNakamaSession} = useAuth();

    const LoginFunc = async(details) => {
        const axiosInstance = axios.create({baseURL: 'https://email-auth.vtsxcode.xyz'});
        let result = await axiosInstance.post('/login', {}, {headers: {'Content-Type': 'application/json', email: details.email, payload: details.password}});
        setAuth({username: result.data.username, authToken: result.data.token});

        // get nakama data...
        let client = await new window.nakamajs.Client("defaultkey", "server.vtsxcode.xyz", "", true);
        let sess = await new window.nakamajs.Session(result.data.token, result.data.refresh_token, result.data.created);
        let accountData = await client.getAccount(sess);

        // store nakama data...
        setnakamaAccount(accountData);
        setNakamaSession(sess);
        setNakamaClient(client);
        
        // go back to desired page...
        nav(from, {replace: true});
    }

  return (
    <div>
      <div>
        <img src={logo} alt="Primary Logo" className="primaryLogo" />;
        <LoginForm LoginProp={LoginFunc} ErrorProp={error} />
        <Notification code={code} />
      </div>
    </div>
  );
}

export default Login;
