function Feed() {
  return (
    <div className="newsFeed">
      <div className="">
        <h2>NEWS FEED</h2>
      </div>
    </div>
  );
}

export default Feed;
