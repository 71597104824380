import { useEffect, useState } from "react";
import { HiUserAdd } from 'react-icons/hi';
import { ImCheckmark, ImCross } from 'react-icons/im';
import useAuth from "../hooks/useAuth";
import stupid from "../assets/images/stupid.png";
function FriendsList() {
  const { nakamaClient, nakamaSession } = useAuth();
  const [friend, setFriend] = useState({friendUsername: ""});
  const [friends, setFriends] = useState(undefined);
  const [friendrequests, setFriendrequests] = useState(undefined);
  const [friendUpdate, setFriendUpdate] = useState(undefined);

  useEffect(() => {
    console.log('listing friends...');
    nakamaClient.listFriends(nakamaSession, 0).then((res) =>{
      let Arr = [];
      for (let i = 0; i < res.friends.length; i++) {
        Arr.push(res.friends[i]);
      }
      if (Arr !== [])
        setFriends(Arr);
    });
  }, [friendUpdate, nakamaClient, nakamaSession]);

  useEffect(() => {
    console.log('listing incoming friend requests...');
    nakamaClient.listFriends(nakamaSession, 2).then((res) =>{
      let Arr = [];
      for (let i = 0; i < res.friends.length; i++) {
        Arr.push(res.friends[i]);
      }
      if (Arr !== [])
        setFriendrequests(Arr);
    });
  }, [friendUpdate, nakamaClient, nakamaSession]);

  const clickHandler = e => {
    e.preventDefault();
    addFriend(nakamaClient, nakamaSession, friend.friendUsername);
  }

  return (
    <div className='outerWholeFriendsList'>
      <div className="wholeFriendsList">
        <h3>Friends</h3>
        {friends ? friends.map((request) => { return <FriendItem friend={request.user}/>}) : <p>Oh... This is awkward...</p>}
        <>
          {friendrequests ? <div>
            <h3>Friend Request</h3>
            {friendrequests.map((request) => { return <FriendRequestItem nakamaClient={nakamaClient} nakamaSession={nakamaSession} request={request.user} setFriendUpdate={setFriendUpdate}/>})}
          </div> : <></>}
        </>
      </div>
      <input
            type="username"
            name="username"
            id="username"
            className="addFriendTextbox"
            onChange={(e) =>
              setFriend({ ...friend, friendUsername: e.target.value })
            }
            value={friend.friendUsername}
          />
      <button className="addFriendButton" onClick={clickHandler}><HiUserAdd size={32}/></button>
    </div>
  );
}

async function addFriend(client, session, username) {
  return await client.addFriends(session, [], [username]);
}

const FriendRequestItem = ({nakamaClient, nakamaSession, request, setFriendUpdate}) => {
  const acceptFriendHandler = e => {
    e.preventDefault();
    addFriend(nakamaClient, nakamaSession, request.username).then((res) => {
      if (res) setFriendUpdate(request.username);});
  }
  return (
    <div className="friendReqCard">
      <img src={request.avatar_url ? request.avatar_url : stupid} alt="" className="friendAvatarImage"/>
      <p>{request.username}</p>
      <div className="friendReqOpt">
        <button className="acceptReqButton" onClick={acceptFriendHandler}><ImCheckmark size={32}/></button>
        <button className="declineReqButton"><ImCross size={32}/></button>
      </div>
    </div>
    );
};

const FriendItem = ({key, friend}) => {
  return (
  <div className="friendCard">
    <img src={friend.avatar_url ? friend.avatar_url : stupid} alt="" className="friendAvatarImage"/>
    <p>{friend.username}</p>
  </div>
  );
};

export default FriendsList;
