import React from "react";
import "../App.css";

function Notification({code}) {
    return ( 
        <div>
        {
            (code === 200) ? <div> <p>Success: email verification sent</p> </div> : <></>
        }
        </div>
    );
};

export default Notification;