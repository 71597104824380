import React from 'react';
import logo from "../assets/images/primary-Logo.png";

function Loading() {
  return (
    <div>
       <div>
           <img src={logo} alt="Primary Logo" className="primaryLogo" />;
       </div>
   </div>
  )
}

export default Loading;